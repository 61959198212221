







































































































import { defineComponent, ref } from '@vue/composition-api'
import { Player } from '@/types/game'
import { backendAPI } from "@/api/backendAPI"
import AnswerAlert from "@/components/answerAlert.vue"


export default defineComponent({

    name: 'RegisterView',

    components: {
        AnswerAlert,
    },

    setup() {

        const answerAlertComp = ref<InstanceType<typeof AnswerAlert>>()

        const valid = ref(false)

        const first_name = ref("")
        const last_name = ref("")
        const nickname = ref("")
        const password = ref("")
        const password_confirm = ref("")
        const mail_address = ref("")

        const rules = {
            required: (value: string) => !!value || 'Campo obbligatorio',
            pwd: (value: string) => value === password.value|| 'Password differenti',
        }

        function send() {

            if (nickname.value !== "" && password.value !== "" && first_name.value !== "" && last_name.value !== "" && password_confirm.value !== "" && mail_address.value !== "") {
                if (password_confirm.value === password.value) {
                    const newPlayer = {
                        nickname: nickname.value,
                        password: password.value,
                        first_name: first_name.value,
                        last_name: last_name.value,
                        avatar: "actor",
                        mail_address: mail_address.value,
                    } as Player

                    backendAPI.registerPlayer(newPlayer)
                    .then(msg => {
                        console.log(msg)
                        answerAlertComp.value?.trigger(msg, true)
                    })
                    .catch(error => {
                        console.error(error)
                        answerAlertComp.value?.trigger(error, false)
                    })
                }
            }
        }

        return {
            answerAlertComp,
            valid,
            send,
            first_name,
            last_name,
            nickname,
            password,
            password_confirm,
            rules,
            mail_address,
        }
    }

})
