



















import { defineComponent, ComputedRef, computed, onMounted, ref } from '@vue/composition-api'
import AnswerAlert from "@/components/answerAlert.vue"
import Player from "@/components/Player.vue"
import * as state from "@/store/modules/app"

export default defineComponent({

    name: "PlayersView",

    components: {
        Player,
        AnswerAlert,
    },

    setup() {
        const players: ComputedRef = computed(() => state.getters.players)
        const answerAlertComp = ref<InstanceType<typeof AnswerAlert>>()


        onMounted(() => {
            console.log("mounted Players View")

            state.actions.fetchPlayers()
            .then(() => {
                console.log("players fetchati")
            })
            .catch(errorMsg => {
                answerAlertComp.value?.trigger(errorMsg, false)
            })

        })

        return {
            players,
            answerAlertComp,
        }
    },
})
