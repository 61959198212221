















import { defineComponent, PropType } from '@vue/composition-api'
import { Player } from '@/types/game'

export default defineComponent({

    name: "Player",

    props: {
        pPlayer: {
            type: Object as PropType<Player[]>,
            required: true,
        }
    },

})
